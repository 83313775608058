import React from 'react';
import { Navigate } from 'react-router-dom';

function ProtectedRoute({ user, children }) {
    // Если пользователь не авторизован, перенаправляем на главную страницу
    if (!user) {
        return <Navigate to="/" />;
    }

    // Если пользователь авторизован, отображаем дочерний компонент
    return children;
}

export default ProtectedRoute;