// src/Analytics.js
import { useEffect } from 'react';
import ReactGA from 'react-ga4';

const Analytics = () => {
  useEffect(() => {
    // Google Analytics
    ReactGA.initialize([
      { trackingId: 'G-2XPR06Q7LF' }, // ID для kypito.ru
    ]);

    const trackPageView = () => {
      ReactGA.send('pageview', window.location.pathname + window.location.search);
    };

    if (window.location) {
      trackPageView();
    }

    // Яндекс.Метрика
    (function (m, e, t, r, i, k, a) {
      m[i] = m[i] || function () { (m[i].a = m[i].a || []).push(arguments); };
      m[i].l = 1 * new Date();
      for (let j = 0; j < document.scripts.length; j++) {
        if (document.scripts[j].src === r) { return; }
      }
      k = e.createElement(t);
      a = e.getElementsByTagName(t)[0];
      k.async = 1;
      k.src = r;
      a.parentNode.insertBefore(k, a);
    })(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

    if (typeof window.ym === "function") {
      window.ym(99448752, "init", {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
      });
    }

    // Чистка при размонтировании компонента
    return () => {
      const img = document.querySelector('img[src="https://mc.yandex.ru/watch/99448752"]');
      if (img) img.parentNode.removeChild(img);
    };
  }, []);

  return null; // Этот компонент ничего не рендерит
};

export default Analytics;