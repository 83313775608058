import { useEffect } from 'react';

const useLazyLoadImages = () => {
  useEffect(() => {
    // Функция для добавления атрибута "loading"
    const setLazyLoading = (img) => {
      if (!img.hasAttribute('loading')) {
        img.setAttribute('loading', 'lazy');
      }
    };

    // Обработка уже существующих изображений
    const images = document.querySelectorAll('img');
    images.forEach(setLazyLoading);

    // Используем MutationObserver для наблюдения за изменениями в DOM
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList') {
          mutation.addedNodes.forEach((node) => {
            if (node.tagName === 'IMG') {
              setLazyLoading(node);
            }
            // Если добавлен элемент с дочерними `img`
            if (node.querySelectorAll) {
              const nestedImages = node.querySelectorAll('img');
              nestedImages.forEach(setLazyLoading);
            }
          });
        }
      });
    });

    // Наблюдаем за всем body
    const config = { childList: true, subtree: true };
    observer.observe(document.body, config);

    // Отключаем наблюдателя при размонтировании
    return () => {
      observer.disconnect();
    };
  }, []);
};

export default useLazyLoadImages;
