import React from 'react';
import './LoadingBar.css'; // Подключаем стили

const LoadingBar = () => {
    return (
        <div className="loading-bar">
            <div className="loader-dots">
                <span>.</span><span>.</span><span>.</span>
            </div>
        </div>
    );
};

export default LoadingBar;