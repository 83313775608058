import React, { useEffect, useState, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import { AiOutlineHome, AiOutlinePlusCircle, AiOutlineUser, AiOutlineArrowLeft } from 'react-icons/ai';
import Analytics from './Analytics'; // Импортируем компонент аналитики
import useLazyLoadImages from './useLazyLoadImages'; // Lazy загрузка 
import { AiOutlineInfoCircle } from 'react-icons/ai'; // Импорт новой иконки
import ProtectedRoute from './ProtectedRoute'; // Импортируем компонент
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom'; // Для React Router v6
import { useLocation } from 'react-router-dom';
import LoadingBar from './LoadingBar'; 
import { Helmet } from 'react-helmet-async'; 
import { HelmetProvider } from 'react-helmet-async';

// Ленивая загрузка компонентов
const Posts = lazy(() => import('./Posts'));
const PostDetail = lazy(() => import('./PostDetail'));
const CreatePost = lazy(() => import('./CreatePost'));
const UserDashboard = lazy(() => import('./UserDashboard'));
const AboutProject = lazy(() => import('./AboutProject'));
const ErrorPage = lazy(() => import('./ErrorPage'));
const TotalUsers = lazy(() => import('./TotalUsers'));

const Footer = React.memo(() => {
    const navigate = useNavigate();
    const location = useLocation(); // Получаем текущий маршрут

    return (
        <footer className="App-footer">
            {/* Кнопка "Назад" */}
            <Link
                to={location.pathname.match(/^\/[^/]+\/[^/]+\/\d+$/) ? "/" : undefined}
                className="footer-link"
                onClick={() => {
                    if (location.pathname.match(/^\/[^/]+\/[^/]+\/\d+$/)) {
                        // Если мы на странице поста с новым форматом URL, возвращаемся в ленту с сохранением позиции
                        const scrollPosition = localStorage.getItem('scrollPosition');
                        navigate('/');
                        if (scrollPosition) {
                            setTimeout(() => {
                                window.scrollTo(0, parseInt(scrollPosition, 10));
                            }, 100);
                        }
                    } else {
                        // Для остальных страниц — просто назад
                        navigate(-1);
                    }
                }}
            >
                <AiOutlineArrowLeft size={30} />
                <span>Назад</span>
            </Link>

            {/* Главная страница */}
            <Link
                to="/"
                className={`footer-link ${location.pathname === '/' ? 'active' : ''}`}
            >
                <AiOutlineHome size={30} />
                <span>Домой</span>
            </Link>

            {/* Личный кабинет */}
            <Link
                to="/dashboard"
                className={`footer-link ${location.pathname === '/dashboard' ? 'active' : ''}`}
            >
                <AiOutlineUser size={30} />
                <span>Кабинет</span>
            </Link>

            {/* Создать объявление */}
            <Link
                to="/create"
                className={`footer-link ${location.pathname === '/create' ? 'active' : ''}`}
            >
                <AiOutlinePlusCircle size={30} />
                <span>Создать</span>
            </Link>

            {/* О проекте */}
            <Link
                to="/support"
                className={`footer-link ${location.pathname === '/support' ? 'active' : ''}`}
            >
                <AiOutlineInfoCircle size={30} />
                <span>О проекте</span>
            </Link>
        </footer>
    );
}); 

function ScrollToHash() {
    const { hash } = useLocation();

    useEffect(() => {
        if (hash) {
            const element = document.getElementById(hash.replace('#', ''));
            if (element) {
                setTimeout(() => {
                    element.scrollIntoView({ behavior: 'smooth' });
                }, 100); // Задержка для завершения рендеринга
            }
        }
    }, [hash]);

    return null;
}

function App() {
    const clearCache = () => {
        console.log('[CACHE] Очищаем кэш');
        localStorage.removeItem('postsCache');
        localStorage.removeItem('postsPage');
        localStorage.removeItem('selectedCategory');
        localStorage.removeItem('scrollPosition');
    };
   
    useEffect(() => {
        console.log('[CACHE] Очищаем кэш при открытии браузера');
        clearCache();
    }, []);
    
    const [user, setUser] = useState(null); // Состояние для хранения данных пользователя
    const [searchTerm] = useState(''); // Состояние для текста поиска
    const [error, setError] = useState({ message: null, link: null }); // Состояние для ошибок
    const [showPopup, setShowPopup] = useState(false);

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    // Подключаем хук ленивой загрузки
    useLazyLoadImages();

    useEffect(() => {
        const savedScrollPosition = localStorage.getItem('scrollPosition');
        if (savedScrollPosition) {
            setTimeout(() => {
                window.scrollTo(0, parseInt(savedScrollPosition, 10));
                localStorage.removeItem('scrollPosition'); // Очищаем после восстановления
            }, 0);
        }
    }, []);

    useEffect(() => {
        if (window.Telegram?.WebApp) {
            const tg = window.Telegram.WebApp;
            tg.ready(); // Инициализация Telegram WebApp
            tg.expand(); // Увеличиваем Web App на всю страницу
            // Очистка кэша при открытии WebApp в Telegram
            tg.onEvent('web_app_ready', () => {
                console.log('[CACHE] Очищаем кэш при открытии Telegram WebApp');
                clearCache();
            });

            // Очистка кэша при закрытии WebApp в Telegram
            tg.onEvent('web_app_close', () => {
                console.log('[CACHE] Очищаем кэш при закрытии Telegram WebApp');
                clearCache();
            });

            const initData = tg.initData; // Получаем initData из Telegram

            console.log('Получено initData');

            // Отправляем запрос для авторизации пользователя
            fetch('https://api.kypito.ru/api/auth', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ initData }),
            })
                .then((response) => {
                    if (!response.ok) {
                        // Если пользователь не авторизован
                        setError({
                            message: 'Привет! Вы зашли без авторизации через Telegram и сможете только просматривать объявления.',
                            link: {
                                text: 'Войдите через телеграмм бота чтобы создавать или редактировать объявления.',
                                url: 'https://t.me/Kypito_bot',
                            },
                        });
                        throw new Error('Пользователь не авторизован.');
                    }
                    return response.json(); // Если авторизация успешна, возвращаем JSON
                })
                .then((data) => {
                    console.log('Ответ сервера авторизации');
                    if (data.status === 'ok' && data.wp_user) {
                        // Если пользователь успешно авторизован
                        setUser({ ...data.user, wp_user: data.wp_user });
                        setShowPopup(true); // Показываем поп-ап
                        console.log('Пользователь успешно авторизован');
                    } else {
                        setError('Авторизация не удалась. Попробуйте позже.');
                        console.error('Ошибка авторизации');
                    }
                })
                .catch((err) => {
                    console.error('Ошибка при авторизации');
                });
        } else {
            // Если Telegram WebApp недоступен
            setError({ message: 'Telegram WebApp недоступен. Проверьте правильность окружения.', link: null });
            console.error('Telegram WebApp недоступен.');
        }
    }, []);

    const [deferredPrompt, setDeferredPrompt] = useState(null);
    
    // 3. Очистка кэша при закрытии браузера
    useEffect(() => {
        const handleUnload = () => {
            console.log('[CACHE] Очищаем кэш при закрытии браузера');
            clearCache();
        };

        window.addEventListener('unload', handleUnload);
        return () => {
            window.removeEventListener('unload', handleUnload);
        };
    }, []);

    useEffect(() => {
        const handleBeforeInstallPrompt = (event) => {
            event.preventDefault(); // Предотвращаем автоматическое отображение подсказки
            setDeferredPrompt(event); // Сохраняем событие для использования позже
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);
    
    const [showAddToHomeScreenPopup, setShowAddToHomeScreenPopup] = useState(false);

    useEffect(() => {
        // Очистка кэша при закрытии WebApp через Telegram API
        if (window.Telegram?.WebApp) {
            const tg = window.Telegram.WebApp;

            // Очистка кэша при закрытии WebApp
            tg.onEvent('web_app_close', () => {
                console.log('[CACHE] Очищаем кэш через Telegram (закрытие)');
                clearCache();
            });

            // Очистка кэша при открытии WebApp
            tg.onEvent('web_app_ready', () => {
                console.log('[CACHE] Очищаем кэш через Telegram (открытие)');
                clearCache();
            });
        }

        // Резерв: очищаем кэш при закрытии браузера
        const handleUnload = () => clearCache();
        window.addEventListener('beforeunload', handleUnload);

        return () => {
            window.removeEventListener('beforeunload', handleUnload);
        };
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowAddToHomeScreenPopup(true);
        }, 120000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <HelmetProvider> {/* Оборачиваем все приложение */}
        <div style={{ marginTop: `85px` }}>
            {/* Остальной контент */}
            <Router>
                <ScrollToHash />
                <div className="App">
                    {/* Отображение ошибок */}
                    {error && error.message && error.message.trim() !== '' && (
                        <div className="error-banner">
                            <div
                                style={{
                                    backgroundColor: '#ffe0e0',
                                    color: '#900',
                                    padding: '5px',
                                    borderRadius: '3px',
                                    margin: '0px 0',
                                    textAlign: 'center',
                                    fontSize: '14px',
                                    lineHeight: '1.2', // Уменьшаем расстояние между строками
                                }}
                            >
                                <p style={{ marginTop: '0px', marginBottom: '0px' }}>{error.message}</p>
                                {error.link && (
                                    <p style={{ marginTop: '0px', marginBottom: '0px' }}>
                                        <a href={error.link.url} target="_blank" rel="noopener noreferrer">
                                            {error.link.text}
                                        </a>
                                    </p>
                                )}
                            </div>
                        </div>
                    )}

                    {showAddToHomeScreenPopup && (
                        <div className="popup-container">
                            <div className="popup">
                                <h4>Добавить Купито на главный экран</h4>
                                <p>Для удобства сохраните приложение на главный экран.</p>

                                {isMobile ? (
                                    // Для мобильных устройств
                                    <button
                                        onClick={() => {
                                            if (window.Telegram?.WebApp) {
                                                const tg = window.Telegram.WebApp;
                                                tg.addToHomeScreen();
                                            }
                                        }}
                                        style={{
                                            padding: '10px 20px',
                                            backgroundColor: '#007bff',
                                            color: '#fff',
                                            border: 'none',
                                            borderRadius: '5px',
                                            cursor: 'pointer',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        Сохранить Купито на главном экране 📌
                                    </button>
                                ) : (
                                    // Для десктопов
                                    <button
                                        onClick={async () => {
                                            if (deferredPrompt) {
                                                deferredPrompt.prompt(); // Показываем предложение установки
                                                const { outcome } = await deferredPrompt.userChoice; // Ждём выбора пользователя
                                                console.log(`Пользователь выбрал: ${outcome}`);
                                                setDeferredPrompt(null); // Сбрасываем событие
                                            }
                                        }}
                                        style={{
                                            padding: '10px 20px',
                                            backgroundColor: '#007bff',
                                            color: '#fff',
                                            border: 'none',
                                            borderRadius: '5px',
                                            cursor: 'pointer',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        Установить Купито на рабочий стол
                                    </button>
                                )}

                                <button
                                    onClick={() => setShowAddToHomeScreenPopup(false)}
                                    style={{
                                        padding: '10px 20px',
                                        backgroundColor: '#ccc',
                                        color: '#000',
                                        border: 'none',
                                        borderRadius: '5px',
                                        cursor: 'pointer',
                                    }}
                                >
                                    Закрыть
                                </button>
                            </div>
                        </div>
                    )}
                
                    {/* Добавляем отступ под панель */}
                    <div style={{ marginTop: '0px' }}>
                        <header className="App-header">
                            {/* Всплывающее окно */}
                            {showPopup && (
                                <div className="popup-container">
                                    <div className="popup">
                                        <h4>Привет, {user ? (user.firstName || user.username) : "Гость"}!</h4>
                                        <h4>Вы успешно авторизованы!</h4>
                                        <p>Теперь вы можете создавать объявления.</p>
                                        <p>Платформа уже объединяет более <TotalUsers /> пользователей.</p>
                                        <p>Остерегайтесь <a href="/support#scams" className="scam-link">мошенников</a>.</p>
                                        <button className="close-popup-btn" onClick={handleClosePopup}>
                                            Закрыть
                                        </button>
                                    </div>
                                </div>
                            )}

                            {/* Основные маршруты приложения */}
                            <Suspense fallback={<LoadingBar />}>
                                <Routes>
                                    <Route path="/" element={<Posts searchTerm={searchTerm} user={user} />} />
                                    <Route path="/403" element={<ErrorPage />} />
                                    <Route path="/category/:category" element={<Posts searchTerm="" user={user} />} />
                                    <Route path="/post/:id" element={<PostDetail />} />
                                    <Route path="/:category/:slug/:id" element={<PostDetail />} />
                                    <Route path="/create" element={<ProtectedRoute user={user}><CreatePost user={user} /></ProtectedRoute>} />
                                    <Route path="/dashboard" element={<ProtectedRoute user={user}><UserDashboard user={user} /></ProtectedRoute>} />
                                    <Route path="/support" element={<AboutProject />} />
                                </Routes>
                            </Suspense>
                        </header>
                    </div>

                    {/* Добавляем Footer */}
                    <Footer />
                </div>
                
                <Analytics /> {/* Подключаем компонент аналитики */}
            </Router>
        </div>
        </HelmetProvider>
    );
}

export default App;